.navbarLink{
    text-decoration: none;
    /* margin: 3%; */
    color: #0000008c;
    font-size: 18px;
    font-weight: 400;
    margin-right: 5%;
    font-family: var(--bs-font-sans-serif);
}
.navbarLink:hover{
    color: #862efb;
}

.navbarLinkActive{
    text-decoration: none;
    margin: 3%;
    color: #862efb;
    font-size: 18px;
    font-weight: 500;

}
.c{
    margin: 20px;
    margin-bottom: 30px;
}
.logo-header{
    width: 40%;
     height: 40%;
    max-width: 100%;
    min-width: 15%;
}
