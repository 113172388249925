.headLine {
    margin-bottom: 1rem !important;
    font-size: 1.2em !important;
    margin-top: 0;
    font-weight: 500;
    line-height: 1.2;
}

.categoryName {
    font-weight: 500 !important;
    margin-top: 1rem !important;
    font-size: .875em;

}

.scroller {
    display: flex;
    width: max-content;
    padding: 0.5rem 0.25rem;
}

.numberEvents {
    font-size: .875em !important;
}

.hShadow:hover {
    box-shadow: 0 3px 6px 0 rgb(0, 0, 0, .2);
    border-radius: 5px;
}

@media (min-width: 992px) {
    .col-lg-2 {
        flex: 0 0 auto;
        width: 15% !important;
    }
}

.shadow:hover {
    box-shadow: 0 3px 6px 0 rgb(0, 0, 0, .2) !important;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
}

.eventDate {
    font-size: .875em;
    line-height: 1.2;
    font-weight: 500 !important;
}

.eventName {
    font-weight: 700;
    font-size: 1rem;
}

.subtitle {
    font-size: .875em !important;
    opacity: 1 !important;
    font-weight: 400 !important;
}

.expo-primary {
    background-color: #862efb !important;
    color: white !important;
}

.expo-primary:hover {
    background-color: #9c55f9 !important;
    color: white !important;
}

.category-icon {
    background-color: #efefef;
}

.bg-test {
    background-color: white !important;
}

.venueName {
    color: #1f5d84;
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    margin-bottom: 2px;
}

.venuePlace {
    --bs-text-opacity: 1;
    color: #6c757d !important;
    font-size: .875em !important;
    margin-bottom: 0px;
}

.btn-view-all {
    color: #6c757d !important;
    background-color: white !important;
    border-color: #6c757d;
    font-weight: 600;
    border-radius: 20px;
}

.btn-view-all:hover {
    color: #fff !important;
    background-color: #6c757d !important;
    /* border-color: #6c757d; */
    font-weight: 600;
}

.btn-case-studies {
    color: #862efb !important;
    background-color: white !important;
    border-color: #862efb !important;
    font-weight: 600;
    border-radius: 20px;

}

.btn-case-studies:hover {
    color: white !important;
    background-color: #862efb !important;
    /* border-color: #862efb; */
    font-weight: 600;
    border-radius: 20px;
}

.random-review {
    background: linear-gradient(180deg, #f0f0f0, #e7e7e7, #d7d7d7, #e7e7e7, transparent);
}

.news-letter {
    padding: 15px;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 20%);
}

/* start style for slider section */
.line-2 {
    color: #1f5d84;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
}

.bi-bookmark:hover {
    color: #862efb;
}

.line-2:hover {
    color: #862efb;
}

.react-multiple-carousel__arrow--left {
    color: black !important;
    padding: 5px !important;
    left: 0px;
    width: 24px;
    height: 24px;
    z-index: 100;
}

/* لتغيير لون السهم الأيمن */
.react-multiple-carousel__arrow--right {
    color: black !important;
    padding: 5px !important;
    right: 0px;
    width: 24px;
    height: 24px;
    z-index: 100;
}

.scroller {
    display: flex;
    width: max-content;
    padding: 0.5rem 0.25rem;
}

.carousel-item-spacing {
    margin-right: 20px;
    /* أو أي هامش ترغب فيه بين العناصر */

}

.react-multi-carousel-item--active {
    margin: 10px !important;
}

/* end style for slider section */