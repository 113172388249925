.btn-primary{
    color: #862efb;
    font-size: 18px;
    font-weight: 600;
}
@media screen and (max-width: 1200px) {
    .col-12 input{
        width: 90% !important;
    }

}

@media screen and (max-width: 767px) {
    .col-12 input{
        width: 90% ;
    }

}