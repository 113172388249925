.page-wrapper:after {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    background: rgba(0, 0, 0, .7);
}

.page-wrapper {
    background-size: cover;
    position: relative;
    height: auto;
    margin-bottom: 0px;
}

.page-wrapper .container {
    padding: 1.6em 12px;
    color: #fff;
    position: relative;
    z-index: 9;
    text-shadow: 0 0 20px #333;
}

.btn-expo:hover {
    background-color: #6f42aa !important;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
}

.page-wrapper img.img-thumbnail {
    max-width: 20vw;
}

.img-130 {
    height: 20vw !important;
    max-height: 130px !important;
}

.page-wrapper a {
    color: #fff;
    /* padding: 0 5px; */
}

.page-wrapper .label {
    text-shadow: 0 0 0 !important;
    font-weight: 400;
    font-size: 15.75px;
}

.label {
    padding: 0.2em 0.6em !important;
}

.label-success {
    background-color: #42810a !important;
}

.bg-orange {
    background-color: #e31006 !important;
}

.fs-12 {
    font-size: 12px !important;
}

.page-wrapper h1 {
    margin: 3px 0;
    font-size: 1.5rem !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.cursor {
    cursor: pointer;
}

.status {
    font-style: italic;
    color: #ccc;
    left: 3px;
}

#tt-tabs-wrapper {
    line-height: 3.143em;
}

#tt-tabs-wrapper .btn.btn-default {
    background: #f2f2f2;
    padding: 0.6em 1em;
    border-radius: 4px;
}

#tt-tabs-wrapper .btn {
    font-size: 12px;
    margin-right: 5px;
}

.btn-g {
    background-color: #d3af37 !important;
    border: 0px solid transparent !important;
}

.btn-default {
    border-color: #ccc;

}

.btn-tab {
    display: inline-block;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid #ccc;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

#tt-tabs-wrapper .btn.btn-lg {
    font-size: 20px;
}

.btn-orange {
    color: white !important;
    background-color: #eb663c !important;
    border-color: #eb663c !important;
    min-width: 150px;

}

.bg-gray-dark {
    background-color: #333545 !important;
}

.fs-20 {
    font-size: 20px !important;
    font-weight: bold;
}

.fw-bold {
    font-weight: 700 !important;
}

#main-wrapper {
    background: #f5f5f5;
    padding: 1.2em 0 10px;
    position: relative;
}

.tt-tabs {
    margin-bottom: 1.3em;
}

.tt-tabs td {
    padding: 0;
}

.tt-tabs>tbody>tr>td>* {
    display: block;
    font-weight: 500;
    background: #fff;
    padding: 16px 8px;
    border: 1px solid #e7e7e7 !important;
    text-align: center;
    color: #333;
    font-size: 90%;
}

.tt-tabs>tbody>tr>td>.active {
    box-shadow: inset 0 -5px 0 #862efb;
}

.badge-grey {
    background-color: #e3e3e3;
    color: #555 !important;
}

.box {
    border: 1px solid #e7e7e7;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 1.3em;
    position: relative;
}

.quicklink-wrap {
    display: flex;
    flex-flow: wrap;
    gap: 1em;
}

.quicklinks1 {
    padding: 0.25em 0.5em;
    border-radius: 0.25em;
    display: inline-flex;
    background-color: #eff5f4;
}

.trBorder tr {
    border-color: #fff !important;
}

.text-blue {
    color: #1f5d84;
}

.btn-official {
    background-color: #fff !important;
    border-color: #ccc !important;
}

.btn-official:hover {
    background-color: #f8f9fa !important;
    border-color: #adadad !important;
}

.other-location {
    border: 1px solid #eee;
    padding: 5px 10px;
    margin: 5px 10px 5px 0px;
}

.progress-circle {
    border-radius: 50%;
    background-color: #007bff;
    position: relative;
}

.progress-circle::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: transparent;
    border: 10px solid #fff;
}

.top-rated {
    vertical-align: middle;
    white-space: nowrap;
    font-size: 60%;
    font-weight: 400;
    margin-left: 5px;
    padding: .2em .6em !important;
    background-color: #5bc0de;
    color: #fff;
}

.quicklinks {
    padding: 0.25em 0.5em;
    border-radius: 0.25em;
    display: inline-flex;
    background-color: #eff5f4;
}

.text-center {
    text-align: center !important;
}

.top-profile {
    display: flex;
    justify-content: center;
    margin-left: auto;
    align-items: center;
}

.color-333 {
    color: #333 !important;
}

.bx-hl:hover {
    box-shadow: 3px 3px 11px 1px #ccc;
}

.visitor .action-button {
    bottom: 1;
    position: absolute;
    width: 80%;
    margin-left: 5px;
}

.visitor .col-md-3 {
    padding-left: 5px;
    padding-right: 5px;
}

.sugg_button {
    border-radius: 3px;
    color: #862efb !important;
    border: 1px solid !important;
}

.sugg_button:hover {
    border-radius: 3px;
    color: #862efb !important;
    border: 2px solid !important;
}

.aud_box_col {
    padding: 5px 0 !important;
    text-align: left;
    font-weight: 500;
}

.bg-expo {
    background-color: #862efb !important;
}

.mapael .map {
    position: relative;
    margin-top: 30px;
}

.btn-blue {
    background-color: #5370a0 !important;
    border: 1px solid #5370a0 !important;
    color: #fff !important;
}

.fs-20 {
    font-size: 20px !important;
}

.border-right-1-gray {
    border-right: 1px solid #e7e7e7;
}

.barclass5 {
    background-color: #88b131 !important;
}
.barclass4 {
    background-color: #9c0 !important;
}

.barclass3 {
    background-color: #ffcf02 !important;
}

.barclass2 {
    background-color: #ff9f02 !important;
}

.barclass1 {
    background-color: #ea1106 !important;
}

.test-5 {
    fill: #88b131 !important;
}
.test-4 {
    fill: #9c0 !important;
}

.test-3 {
    fill: #ffcf02 !important;
}

.test-2 {
    fill: #ff9f02 !important;
}

.test-1 {
    fill: #ea1106 !important;
}

.gallary-card-review {
    /* width: 32%;
    height: 150px;
    margin: 0 5px; */
    animation: 2s slide;
    /* position: relative; */
    display: inline-block;
    box-shadow: rgba(0, 0, 0, .2) 0 2px 4px 0;
    border-radius: 6px;
    overflow: hidden;
}

.img-review {
    margin: 0px 5px;
    /* filter: blur(2px); */
    /* -webkit-filter: blur(2px); */
}

.img-48 {
    width: 48px !important;
    height: 48px !important;
}

.suggestion-tag {
    border: 1px solid rgba(0, 0, 0, .2);
    background-color: rgba(197, 197, 197, 0.2);
    border-radius: 16px;
    margin-top: 2px;
    font-size: 14px;
    color: rgba(0, 0, 0, .7);
    padding: 0 12px;
    width: 100%;
    line-height: 30px;
    display: inline-block;
    -webkit-box-sizing: border-box;
}

.helpul-btn {
    display: inline-block;
    border: 1px solid #e8e8e8;
    padding: 5px 10px;
    border-radius: 3px;
    text-decoration: none !important;
    color: rgba(0, 0, 0, .7);
    font-size: 12px;
    position: relative;
}

.tb-list tr:nth-child(odd) {
    background-color: #f3f3f3;
}

.tb-list tr:nth-child(2n):hover,
.tb-list tr:nth-child(odd):hover {
    background-color: #eee;
}

.tb-list td {
    padding: 15px 10px;
}

.tb-list td.line {
    border-left: 2px solid #862efb;
}

.deep-shadow {
    box-shadow: 0 1px 4px rgba(0, 0, 0, .18);
}

.intrestNumbers {
    color: rgba(9, 79, 183, 1);
}

.box-210 {
    height: 210px;
}

.btn-follow {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 3px;
    margin-bottom: 5px;
    height: 29px;
    background: white;
    font-weight: 500;
    padding: 0px 0px 0px 0px;
    color: rgba(9, 79, 183, 1);
    border: 1px solid #5370a0;
}

.btn-follow:hover {

    border: 2px solid #5370a0;
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.camposition {
    position: absolute;
    top: 8px;
    right: 15px;
    pointer-events: none;
}

.w-95 {
    width: 95%;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.feedposition {
    max-height: 4.5em;
    max-width: 95%;
    /* overflow: scroll; */
    position: relative;
    margin-left: -20px;
    text-overflow: ellipsis;
}

.likeposition {
    background-color: #f8f9fa;
    padding: 0.5rem;
}

.vf-rating {
    border: none;
    float: left;
    font-size: 18px;
    cursor: pointer;
}

.vf-rating>input {
    display: none;
}

.vf-rating>label {
    color: #ddd;
    float: right;
}

.vf-rating:not(:checked)>label>:hover {
    color: #862efb;
    /* background-color: #862efb; */
}

.image-upload {
    display: inline-block;
    border: 1px solid #efefef;
    background: #fafafa;
    height: 100px;
    width: 100px;
    margin: 10px 10px 10px 0;
    position: relative;
    cursor: pointer;
}

.image-upload>input {
    display: none;
}

.vf-question {
    background-color: rgba(197, 197, 197, 0.2);
    border: none;
    color: #777;
    cursor: pointer;
}

.vf-answer {
    background-color: #88b131;
    border: none;
    color: #fff;
    cursor: pointer;
}

.vf-yes {
    background: #88b131 !important;
}

.vf-no {
    background: rgba(234, 17, 6, 1) !important;
    color: #fff;

}

.vf-may {
    background: #ffcf02 !important;
    color: #fff;
}


.suggestion-tag-new {
    border-radius: 16px;
    margin-top: 2px;
    font-size: 14px;
    padding: 0 12px;
    width: 100%;
    line-height: 30px;
    display: inline-block;
    -webkit-box-sizing: border-box;
}

.img-48 {
    width: 48px !important;
    height: 48px !important;
}

.helpul-btn {
    display: inline-block;
    border: 1px solid #e8e8e8;
    padding: 5px 10px;
    border-radius: 3px;
    text-decoration: none !important;
    font-size: 12px;
    position: relative;
}

.helpul-btn:hover {
    background-color: #eff2f6;
    /* color: #862efb; */
}

.input-search-follower {
    padding: 8px 5px 7px;
    display: block;
    line-height: 20px;
    background: 0;
    color: #636363;
    width: 100%;
    border: 0;
    border-radius: 0;
    font-weight: 400;
    border-bottom: 1px solid #727272;
    letter-spacing: 1px;
    outline: 0;
    -webkit-appearance: none;
}

#search2 {
    padding: 8px 5px 7px;
    display: block;
    line-height: 20px;
    background: 0;
    color: #636363;
    width: 100%;
    border: 0;
    border-radius: 0;
    font-weight: 400;
    border-bottom: 1px solid #727272;
    letter-spacing: 1px;
    outline: 0;
    -webkit-appearance: none;
}
.triangle {
    width: 0;
    height: 0;
    top: 0;
    border-top: 50px solid rgba(51,122,183,.7);
    border-right: 50px solid transparent;
    left: 0;
    position: absolute;
}
.triangle>span {
    position: absolute;
    font-size: 12px;
    color: #fff;
    top: -40px;
    left: -1px;
    transform: rotate(-45deg);
}
.navbar-nav-scroll {
    max-height: var(--bs-scroll-height,75vh);
   
}

.btn-follow{
    border-color: rgba(51,122,183,.7);
    color: rgba(51,122,183,.7) !important;
    width: 100%;
    padding-inline: 1rem;
}
.visitor .heart {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
.scoller {
    overflow-x: auto;
    overflow-y: hidden;
    width: inherit !important;
    white-space: nowrap;
   
}
.btn-register{
    font-size: medium !important;
}