.btn-outline-expo {
    color: #862efb !important;
    border-color: #862efb !important;
}
.btn-outline-expo:hover {
    color: white !important;
    background-color: #862efb !important;
    border-color: #862efb !important;
}
.btn-outline-expo:hover {
    color: white !important;
    background-color: #862efb !important;
    border-color: #862efb !important;
}

.table>:not(caption)>*>* {
    padding: 1rem 1rem !important;
}