.test{
    align-items: center;
    display: flex;
    justify-content: end;
}
.w-10{
    width: 10% !important;
}
.sticky-top {
    position: sticky !important;
    top: 0 !important;
    z-index: 1000; /* تحديد ترتيب الطبقات حسب الحاجة */
  }
  .img-avatar{
    width: 20%;
  }