.related-search:hover {
    color: #862efb !important;
}

.btn-primary {
    background-color: #862efb !important;
    border-color: #862efb !important;
}

#filters {
    max-width: 100%;
    width: 306px;
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, .18);
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, .18);
    box-shadow: 0 1px 4px rgba(0, 0, 0, .18);
}

.fs-12 {
    font-size: 12px;
}

.form-check-input:hover {
    background-color: #862efb;
}

.form-check-label:hover {
    color: #862efb;
}
.event-date{
    line-height: 1.2;
    /* font-family: var(--bs-font-sans-serif); */
    font-family: var(--bs-body-font-family);
    /* font-size: 1rem; */
   
    font-weight: 500;
}
.event-name{
    font-size: 1.3em!important;
    font-weight: 500;
    line-height: 1.2;
    font-family: var(--bs-body-font-family);
    color: #1f5d84;
}
.event-name:hover{
    color: #862efb;
}
.event-img{
max-width: 90%;
}
.event-location{
    
}
.event-prev{
    color: #5e5e5e;
    line-height: 1.2;
    font-size: .875em;
    font-family: var(--bs-body-font-family);
    font-weight: var(--bs-body-font-weight);
}
.event-category:hover{
    color: #862efb !important;
}
.event-topic{
    cursor:text;
}
.interested:hover{
    color: #862efb !important;
}
.badge{
    font-size: 13px !important;
}
.fw-500{
    font-weight: 500 !important;
}
#featured-events{
    /* background: linear-gradient(90deg, #F8F5F0, #F4CB8C); */
}
.featured-event-name{
    color: #1f5d84;
}
.featured-event-name:hover{
    color:#862efb;
}
.bi-bookmark-fill:hover{
    color:#862efb;
}
.fw-600{
    font-weight: 600;
}