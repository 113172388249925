.bi-search:hover {
    color: #862efb;
}

.xn:not(.active):hover {
    color: #862efb !important;
    cursor: pointer;
}

.line-1 {
    display: block;
    height: 1.5em;
    white-space: nowrap;
}
.box-shadow {
    box-shadow: 0 3px 6px 0 rgb(0,0,0,.2);
}
.box-shadow:hover {
    -webkit-transition: .4s;
    box-shadow: 0 3px 6px 0 rgb(0,0,0,.4);
}

.flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
}
.modal.custom .modal-dialog {
    max-width: 50% !important;
    margin:0 auto !important;
    /*add what you want here*/
}

