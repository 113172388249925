
.your-section {
    /* أسلوب القسم عندما لا يتم التمرير */
    /* ... */
  }
  
  .your-section.sticky {
    position: fixed;
    top: 45px;
    width: 20%;
    z-index: 999;
  }
  