.ticket {
    position: relative !important;
    background: #fff;
    padding: 10px;
    margin-left: 9px;
    margin-right: 9px;
    border-radius: 5px;
}


.whySignup {
    float: right;
    font-size: 12px;
    margin-right: 0.25rem;
    cursor: pointer;
    text-decoration: none;
    opacity: 0.8;
    margin-top: 5px;
    /* color: #1f5d84; */
}

.loginorsignup {
    margin-left: 5rem;
}

.shadow-login {
    box-shadow: 0 3px 6px 0 rgb(0, 0, 0, .2);
}

.shadow-login:hover {
    box-shadow: 0 3px 6px 0 rgb(0, 0, 0, .4);
    -webkit-transition: .4s;
}

.social_btn_wrapper {
    overflow: hidden;
    margin: 30px 0px;
}

.social_btn_wrapper .social_btn {
    width: 43%;
    float: left;
    margin: 13px 16px !important;
}
.card-header{
    padding: 24px 24px 0 24px !important;
}
.btn-expo{
    background-color: #862efb !important;
    color: white !important;
}
.model-mobile{
    background-color: transparent;
}

/* make it responsive for Ipad */
@media screen and (max-width: 1200px) {
    .parent {
        width: 90% !important;
    }
    .social_btn_wrapper .social_btn {
        width: 50%;
        float:none;
        margin: 13px  16px !important;
    }
    .index_desktop input{
        width: 80% !important;
    }
    #send{
        width: 40% !important;
    }
}


/* make it responsive for Mobile */
@media screen and (max-width: 767px) {
    body {
        font-size: 16px !important;;
    }
    .m-auto{
        margin: 0  !important;
    }
    .parent {
        width: 100% !important;
    }
    .social_btn_wrapper .social_btn {
        width: 90%;
        float: left;
        margin: 13px 16px !important;
    }
    .index_desktop input{
        width: 80% !important;
    }

}

