.imgSlider{
    width: 100%;
}
.coutom-btn{
    margin: 1%;
    background-color: transparent !important;
    color: black !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
}
.coutom-btn:hover{
    background-color: #6c757d !important;
    color: white !important;

}

    