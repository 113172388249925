.paraFooter{
    display: block;
    text-decoration: none;
    color: #495057;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 1.1px;
    font-family: "Roboto", Sans-serif;    
}
.paraFooter:hover{
    background-color: #f8f9fa;
}
.copyright{
    color: #212529;
    font-family: "Roboto", Sans-serif;
    font-size: 1rem;
    font-weight: 400;
}
.line-hr{
    font-weight: bold;
    border: 2px solid black !important;
    width: 99%;
    margin: auto;
    margin-bottom: 15px;
}
.logo-footer{
    width: 40%;
     height: 40%;
    max-width: 100%;
    min-width: 15%;
    
}
